import $ from 'jquery'

let ActionResult = {
  /**
   * Menu component
   *
   * Adds a sidebar menu to the document
   */
  init: function () {
    $('body').addClass('actionresult')
  }
}

export default ActionResult
